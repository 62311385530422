export const hcpHome: any = {
  hcpHomeMainMenu: "Main Navigational Menu HCP Retina Brazil (PT)",
  hcpHomeFooterMenu: "Footer Navigation Menu HCP Retina Brazil (PT)",
  hcpHomeCarousel: "Homepage Carousal HCP - Brazil (PT)",
  hcpFooter: "Footer - HCP - Retina Brazil (PT)",
  hcpExitPopup: "Exit Popup HCP Brazil (PT)",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  hcpTopNav: "Top Navigation Menu HCP Retina Brazil (PT)",
  hcpFooterCallout: "HCP Homepage Footer Navigation Callout HTML - Retina Brazil (PT)",
  hcpSiteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  hcpAmericalData: "American Academy of Ophthalmology HTML - Retina Brazil (PT)",
  hcpAdvancingScience: "Advancing Science HTML - Retina Brazil (PT)",
  hcpOverlap: "Overlapping presentations of IRD HTML - Retina Brazil (PT)",
  hcpHomeFootRef: "HCP Home Reference - Retina Brazil (PT)",
  hcpHomeVideoCarousel: "DNA Video and Carousel - Retina Brazil (PT)",
  backToTopGTM: "back to top - homepage - hcp",
  carousalAttrs: {
    playBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'hero image - play - hcp',
      'aria-label': 'Início diapositivas'
    },
    pauseBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'hero image - pause - hcp',
      'aria-label': 'Pausar diapositivas'
    },
    btnTexts: {
      'play': 'INICIAR',
      'pause': 'PAUSAR'
    },
    playBtnAriaDescription: ' ',
    pauseBtnAriaDescription: ' '
  },
  videoCarousalAttrs: {
    playBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'new gene variants - play',
      'aria-label': 'Início diapositivas'
    },
    pauseBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'new gene variants - pause',
      'aria-label': 'Pausar diapositivas'
    }
  },
  footerClassName: "home-footer"
}
