import React from 'react';
import { graphql } from 'gatsby';
import { HTMLRenderer, Layout, PageWrapper, SimpleSlider, HcpHomePageWrapper } from '@retina-packages/retina-theme-bootstrap';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import { AdvancingScienceCalloutWrapper, AmericanDataCalloutWrapper, HcpFooterBlockWrapper, OverlappingInheritedRetinalDiseaseWrapper } from './styles';
import TextCarousel from '@retina-packages/retina-theme-bootstrap/packages/components/Carousels/TextCarousel';
import Video from "@retina-packages/retina-theme-bootstrap/packages/images/dna-video-bg.mp4";
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { CarouselResponceData } from '@retina-packages/retina-theme-bootstrap/packages/types/ImageCarouselType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { hcpHome } from './constants';
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder, textCarouselBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';
import { HcpPage } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle';
import { EsHeaderContainer } from '../../../css/common/style';

/** Rendering template for HCP Home
 *
 * @param props props
 * @returns
 */
const EmergingScienceTemplate = (props: Props) => {

  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso;
  const pgContextsHHM = props.pageContext
  const htmlStaticFilesHHM = {
    nonSVGImages: pgContextsHHM.nonSVGImages,
    svgMediaImages: pgContextsHHM.svgMediaImages,
    allMediaDocument: pgContextsHHM.documents
  }
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpHome.hcpHomeMainMenu, siteFooterMenu: hcpHome.hcpHomeFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });

  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name;
  const bannerTitle = ""
  const carouselTitle = hcpHome.hcpHomeCarousel
  const footerTitle = hcpHome.hcpFooter
  const exitPopupTitle = hcpHome.hcpExitPopup
  const hcpSwitcherTitle = hcpHome.hcpHCPSwitcher
  const topNavTitle = hcpHome.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs,
    carouselData
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
    carouselData: CarouselResponceData;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const americanAcademyData = fullHTMLDataBuilder({ blocks, title: hcpHome.hcpAmericalData });
  // DNA carousel video Section Data
  const carouselSlideData: CarouselResponceData = textCarouselBuilder({ blocks, title: hcpHome.hcpHomeVideoCarousel });

  const advanceScienceDesc = fullHTMLDataBuilder({ blocks, title: hcpHome.hcpAdvancingScience });

  const overlappData: string = fullHTMLDataBuilder({ blocks, title: hcpHome.hcpOverlap });

  const hcpHomeReference = footRefHTMLBuilder({ blocks, title: hcpHome.hcpHomeFootRef });

  const siteLogos: any = deriveMultiLogo({ blocks, title: hcpHome.hcpSiteLogo })

  const footerCallOutData: string = fullHTMLDataBuilder({ blocks, title: hcpHome.hcpFooterCallout });

  const socialBlock = footRefHTMLBuilder({ blocks, title: hcpHome.socialBlock });

  return (
    <div className='hcppage'>
    <HcpPage>
      <HcpHomePageWrapper className="hcphomepage-wrapper">
        <EsHeaderContainer className="esheadercont">
          {props.pageContext !== null && props.pageContext.metaInfo !== null && (
            <MetaTagContainer metaData={props.pageContext.metaInfo} />
          )}
          <Layout
            title={"siteTitle"}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            location={props.location} data={mainMenu}
            mdata={footerMenu}
            socialBlock={socialBlock}
            footerData={footerText}
            audience={audience}
            footref={hcpHomeReference}
            exitPopData={exitData}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            siteLogos={siteLogos}
            gtmBackToTopLabel={hcpHome.backToTopGTM}
            backToTopContent={retinaConfig.backToTopContent}
            staticMediaFiles={htmlStaticFilesHHM}
            showRefContent={retinaConfig.showRefContent}
            hideRefContent={retinaConfig.hideRefContent}
            hcpValidate={retinaConfig.hcpValidate}
            preIndexUrl = {retinaConfig.preIndexUrl}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            footerClassName={hcpHome.footerClassName}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
          >
            <PageWrapper className='pagewrapper hcp-homepage'>
              {carouselData && (
                <SimpleSlider
                  data={carouselData}
                  {...hcpHome.carousalAttrs}

                />
              )}
              <div className='o-container hcp-home-footer'>
                <AmericanDataCalloutWrapper className="hcpamerican">
                  <HTMLRenderer html={americanAcademyData} data={htmlStaticFilesHHM} tagName={'div'} />
                </AmericanDataCalloutWrapper>
                <TextCarousel carouselSlideData={carouselSlideData} Video={Video} {...hcpHome.videoCarousalAttrs} />
                <AdvancingScienceCalloutWrapper className='c-advance-scienece o-text-center'>
                  <HTMLRenderer html={advanceScienceDesc} data={htmlStaticFilesHHM} tagName={'div'} />
                </AdvancingScienceCalloutWrapper>
                <OverlappingInheritedRetinalDiseaseWrapper className='video-section o-inner video-section-home o-top-space'>
                  <HTMLRenderer html={overlappData} data={htmlStaticFilesHHM} tagName={'div'} className={'video-section__inner-content'} />
                </OverlappingInheritedRetinalDiseaseWrapper>
              </div>
              <HcpFooterBlockWrapper className='o-container hcp-home-footer'>
                <HTMLRenderer html={footerCallOutData} data={htmlStaticFilesHHM} tagName="div" className='footer-block footer-block-bg' />
              </HcpFooterBlockWrapper>
            </PageWrapper>
          </Layout>
        </EsHeaderContainer>
      </HcpHomePageWrapper>
    </HcpPage>
    </div>
  )
};

export default EmergingScienceTemplate;

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      field_meta_tags {
        title
        description
      }
      relationships {
        ...HcpHomePageQuery
      }
    }
  }
`
